body {
  margin: 0;
  font-family: 'Axiforma', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Axiforma";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("../public/fonts/axiforma/Axiforma-Bold.woff2");
}

@font-face {
  font-family: "Axiforma";
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: url("../public/fonts/axiforma/Axiforma-ExtraBold.woff2");
}

@font-face {
  font-family: "Axiforma";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url("../public/fonts/axiforma/Axiforma-Light.woff2");
}

@font-face {
  font-family: "Axiforma";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/axiforma/Axiforma-Medium.woff2");
}

@font-face {
  font-family: "Axiforma";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/axiforma/Axiforma-Regular.woff2");
}

@font-face {
  font-family: "Axiforma";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url("../public/fonts/axiforma/Axiforma-SemiBold.woff2");
}

@font-face {
  font-family: "Axiforma";
  font-display: swap;
  font-style: normal;
  font-weight: 100;
  src: url("../public/fonts/axiforma/Axiforma-Thin.woff2");
}

@font-face {
  font-family: "Open Sans";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url("../public/fonts/opensans/OpenSans-Light.woff2");
}

@font-face {
  font-family: "Open Sans";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/opensans/OpenSans-Regular.woff2");
}

@font-face {
  font-family: "Open Sans";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url("../public/fonts/opensans/OpenSans-SemiBold.woff2");
}

@font-face {
  font-family: "Open Sans";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("../public/fonts/opensans/OpenSans-Bold.woff2");
}

@font-face {
  font-family: "Open Sans";
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: url("../public/fonts/opensans/OpenSans-ExtraBold.woff2");
}

* {
  font-family: "Axiforma", Arial;
}
